// extracted by mini-css-extract-plugin
export var content = "style-module--content--51025";
export var h1 = "style-module--h1--d1225";
export var heroContainer = "style-module--heroContainer--ca9b4";
export var note = "style-module--note--84e01";
export var pageWrapper = "style-module--pageWrapper--5be7e";
export var remote = "style-module--remote--c25c6";
export var role = "style-module--role--42c7d";
export var roles = "style-module--roles--876d6";
export var row = "style-module--row--4cd39";
export var subline = "style-module--subline--7412c";
export var title = "style-module--title--181d4";