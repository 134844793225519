import Template from '../Template'
import * as style from './style.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageCareers = () => {
  return (
    <Template>
      <div className={style.pageWrapper}>
        <Helmet>
          <title>Careers | doggos.com</title>
        </Helmet>
        <div className={style.heroContainer}>
          <h1>Careers</h1>
          <p className={style.subline}>
            Join a small, growth-stage startup making a big impact on the dog
            industry.
          </p>
        </div>
        <div className={style.content}>
          <div className={style.roles}>
            <div className={style.role}>
              <div className={style.row}>
                <span>
                  <p className={style.title}>
                    Senior frontend engineer (ReactJS, TypeScript)
                  </p>
                </span>
                <span>
                  <p className={style.remote}>Remote</p>
                </span>
              </div>
              <span>
                <p>Full-time • Not currently hiring</p>
              </span>
            </div>
            <div className={style.role}>
              <div className={style.row}>
                <span>
                  <p className={style.title}>Customer service specialist</p>
                </span>
                <span>
                  <p className={style.remote}>Remote</p>
                </span>
              </div>
              <span>
                <p>Full-time • Not currently hiring</p>
              </span>
            </div>
          </div>
        </div>
        <div className={style.content}>
          <h4>Questions</h4>
          <p>
            We don't collect speculative resumes, but if you have questions
            about upcoming roles you can contact us{' '}
            <a href='mailto:careers@doggos.com'>via email</a> and we'll try to
            get back to you as soon as possible.
          </p>
        </div>
      </div>
    </Template>
  )
}

export default PageCareers
