import PageCareers from '../components/PageCareers'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageCareers {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default PageCareers
